<template>
  <div>
    <h1 class="title is-size-2 is-size-4-mobile has-text-dark has-text-centered pb-5">
      {{ $t('index.heading-rules') }}
    </h1>
    <div class="container pt-5">
      <div class="card-gallery">
        <div class="card-gallery-box has-background-light">
          <div class="card-gallery-img">
            <img src="~/assets/img/icons/click.svg" alt="" width="130" height="60">
          </div>
          <div class="card-gallery-text-box">
            <h1 class="card-gallery-title title has-text-dark is-size-5">
              {{ $t('card-gallery.usability-title') }}
            </h1>
            <p class="card-gallery-text">
              {{ $t('card-gallery.usability-text') }}
            </p>
          </div>
        </div>
        <div class="card-gallery-box has-background-light">
          <div class="card-gallery-img">
            <img src="~/assets/img/icons/crown.svg" alt="" width="130" height="60">
          </div>
          <div class="card-gallery-text-box">
            <h1 class="card-gallery-title title has-text-dark is-size-5">
              {{ $t('card-gallery.customer-title') }}
            </h1>
            <p class="card-gallery-text">
              {{ $t('card-gallery.customer-text') }}
            </p>
          </div>
        </div>
        <div class="card-gallery-box has-background-light">
          <div class="card-gallery-img">
            <img src="~/assets/img/icons/chat.svg" alt="" width="130" height="60">
          </div>
          <div class="card-gallery-text-box">
            <h1 class="card-gallery-title title has-text-dark is-size-5">
              {{ $t('card-gallery.clarity-title') }}
            </h1>
            <p class="card-gallery-text">
              {{ $t('card-gallery.clarity-text') }}
            </p>
          </div>
        </div>
        <div class="card-gallery-box has-background-light">
          <div class="card-gallery-img">
            <img src="~/assets/img/icons/lens.svg" alt="" width="130" height="60">
          </div>
          <div class="card-gallery-text-box">
            <h1 class="card-gallery-title title has-text-dark is-size-5">
              {{ $t('card-gallery.transparency-title') }}
            </h1>
            <p class="card-gallery-text">
              {{ $t('card-gallery.transparency-text') }}
            </p>
          </div>
        </div>
        <div class="card-gallery-box has-background-light">
          <div class="card-gallery-img">
            <img src="~/assets/img/icons/heart.svg" alt="" width="130" height="60">
          </div>
          <div class="card-gallery-text-box">
            <h1 class="card-gallery-title title has-text-dark is-size-5">
              {{ $t('card-gallery.honesty-title') }}
            </h1>
            <p class="card-gallery-text">
              {{ $t('card-gallery.honesty-text') }}
            </p>
          </div>
        </div>
        <div class="card-gallery-box has-background-light">
          <div class="card-gallery-img">
            <img src="~/assets/img/icons/investors.svg" alt="" width="130" height="60">
          </div>
          <div class="card-gallery-text-box">
            <h1 class="card-gallery-title title has-text-dark is-size-5">
              {{ $t('card-gallery.investor-title') }}
            </h1>
            <p class="card-gallery-text">
              {{ $t('card-gallery.investor-text') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
}

.card-gallery-title {
  margin-top: 0;
  margin-bottom: 10px;
}

.card-gallery-text {
  margin: 0;
}

.card-gallery-box {
  display: flex;
  gap: 30px;
  align-items: center;
  max-width: 500px;
  min-height: 200px;
  padding: 25px;
  border-radius: 10px;
}

.card-gallery-img {
  max-width: 100px;
}

.card-gallery-text-box {
  width: 100%;
}

@media screen and (max-width: 950px) {
  .card-gallery {
    gap: 25px;
  }

  .card-gallery-box {
    flex-direction: column;
    gap: 20px;
  }
}
</style>
